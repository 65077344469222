import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { useMousePos } from "./useMousePos";


export function HoverScrubber({
  externalSprite,
  scrubber = (
    <>
      <CSSline />
    </>
  ),
  callback = () => void 0,
  scrub = true,
  thumb = true,
  width = 120,
  height = 88,
  // spriteWidth = 60,
  // spriteHeight = 44,
  spriteWidth = 320,
  spriteHeight = 240,
  spriteCount = 100,
  spritePerRow = 10,
  // spriteFullWidth = 600,
  // spriteFullHeight = 450,
  spriteFullWidth = 3200,
  spriteFullHeight = 2400,
  drag = false,
  // sprite = 'https://i.ytimg.com/sb/qsxQVTGdMTA/storyboard3_L1/M0.jpg?sqp=-oaymwENSDfyq4qpAwVwAcABBqLzl_8DBgiE4Nv8BQ==&sigh=rs%24AOn4CLBgZ1FP-ATu720AlYI7k-LEGLsWjA',
  // sprite = 'https://res.cloudinary.com/generalsymbol/image/upload/v1639770770/samples/out_vkcckn.jpg',
  sprite = "",
}) {
  const [ratio, setRatio] = useState(width / spriteWidth);
  useEffect(() => {
    if (ratio !== width / spriteWidth) setRatio(width / spriteWidth);
    // console.log('width = ', width);
    // console.log('ratio = ', ratio);
  }, [width]);

  let rowDividerWidth = 1; //For youtube thumbs

  const thumbnail = useRef();
  const { posX, posY } = useMousePos(
    scrub ? (thumbnail.current ? thumbnail.current : document) : null
  );
  const [spriteXpos, setSpriteX] = useState(0);
  const [spriteYpos, setSpriteY] = useState(0);

  const [spriteCurrent, setSpriteCurrent] = useState(0);

  const [opacity, setOpacity] = useState(1);

  // useEffect(() => {
  //   if (typeof externalSprite !== 'undefined' && externalSprite !== spriteCurrent) {
  //     setOpacity(1)
  //     setTimeout(() => {
  //       setOpacity(0)
  //     }, 2000);
  //   }
  // }, [externalSprite])

  useEffect(() => {
    if (
      typeof externalSprite !== "undefined" &&
      externalSprite !== spriteCurrent
    ) {
      setSpriteCurrent(externalSprite);
    }
  }, [externalSprite]);

  let segmentWidth = width / spriteCount;
  let p = width / posX / 10;
  function between(x, min, max) {
    return x >= min && x <= max;
  }
  let segmentArray = Array.apply(null, Array(spriteCount)).map(
    (one, ind) => Math.round(segmentWidth * (ind + 1) * 10) / 10
  );
  // console.log('segmentArray = ', segmentArray);

  useEffect(() => {
    if (!externalSprite) {
      segmentArray.forEach((one, ind) => {
        if (between(posX, one, segmentArray[ind + 1])) {
          setSpriteCurrent(ind);
          // console.log('ind = ', ind);
        }
      });
    }
  }, [posX]);

  const getSprite = (number, spritePerRow) => {
    // console.log('number = ', number);
    let yRow = Math.floor(number / spritePerRow);
    let xCol = number - spritePerRow * yRow;
    // console.log('xCol = ', xCol);
    // console.log('yRow = ', yRow);
    return {
      x: xCol * spriteWidth * ratio,
      y: yRow * (spriteHeight + rowDividerWidth) * ratio,
    };
  };

  let debug = false;

  return (
    <>
      <CSSthumb0
        ref={thumbnail}
        width={width}
        height={thumb ? height : height}
        sprite={sprite}
        spriteX={getSprite(spriteCurrent, spritePerRow)["x"]}
        spriteY={getSprite(spriteCurrent, spritePerRow)["y"]}
      >

        {debug && scrub && (
          <>
            <div>{`Mouse X: ${posX}`}</div>
            <div>{`Mouse Y: ${posY}`}</div>
            <div>{`Sprite: ${spriteCurrent}`}</div>
            <div>
              {`X: ${getSprite(spriteCurrent, spritePerRow)["x"]}`} -{" "}
              {`Y: ${getSprite(spriteCurrent, spritePerRow)["y"]}`}
            </div>
          </>
        )}

        {thumb && (
          <CSSsprite
            opacity={externalSprite ? opacity : 1}
            sprite={sprite}
            spriteFullWidth={spriteFullWidth * ratio}
            spriteFullHeight={spriteFullHeight * ratio}
            spriteX={getSprite(spriteCurrent, spritePerRow)["x"]}
            spriteY={getSprite(spriteCurrent, spritePerRow)["y"]}
          />
        )}

        {scrub && <CSSscrubber className='_css_scrubber' x={posX}>{scrubber}</CSSscrubber>}
      </CSSthumb0>
    </>
  );
}


const CSSline = styled.div`
  width: 4px;
  height: 100%;
  background: teal;
  top: 0;
  left: 0;
  `
const CSSscrubber = styled.div`
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${({ x }) => `${x}px`};
  z-index: 2;
  `

const CSSsprite = styled.div`
  transition: opacity 200ms ease;
  opacity: ${({ opacity }) => opacity};
  background-image: url(${({ sprite }) => sprite});
  background-position: 0 0;
  background-size: cover;
  width: ${({ spriteFullWidth }) => `${spriteFullWidth}px`};
  height: ${({ spriteFullHeight }) => `${spriteFullHeight}px`};
  position: absolute;
  z-index: 0;
  /* left: 0; */
  top: 0;
  left: ${({ spriteX }) => `-${spriteX}px`};
  top: ${({ spriteY }) => `-${spriteY}px`};
  pointer-events: none;
`


const CSSthumb0 = styled.div`
  overflow: hidden;
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  & ._css_scrubber {
    opacity: 0;
  }
  &:hover ._css_scrubber {
    opacity: 1;
  }
  /* background: #ccc; */
  /* background-position: ${({ spriteX, spriteY }) => `${spriteX}px ${spriteY}px`}; */
`


